.subadmin-wrap,
.permission-form {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.permission-form {
    margin-bottom: 0;
    padding: 15px 15px 3px 15px;
}
.subadmin-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
h1,
h3 {
    font-weight: bold;
    color: #000000;
}
h1 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}
.btn-group {
    padding: 0;
}
.btn-group button {
    margin-right: 15px;
}
.btn-group button:last-child {
    margin-right: 0;
}
.breadcrumbs {
    margin: 15px 0 5px;
}
.breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.form-checkbox span {
    font-size: 15px;
    font-weight: 700;
}
.radio-btn span {
    font-size: 15px;
}
.radio-btn label {
    margin-right: 25px;
}
.add-permission-form {
    padding: 15px 15px 3px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.add-form-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.add-form-col {
    max-width: 33.33%;
    padding: 0 10px;
    flex: 0 33.33%;
}
.add-permission-form h3 {
    margin: 15px 0 5px;
}
.addadmin-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.addadmin-col {
    max-width: 50%;
    flex: 0 50%;
    padding: 0 8px;
}
.addadmin-col.top-spacing {
    margin-top: 15px;
}
.country-name-section {
    display: flex;
}
.country-code {
    width: 155px;
    margin-right: 6px;
}
.country-text {
    width: calc(100% - 161px);
}
.add-permission-row {
    display: flex;
}
.permission-left {
    width: calc(100% - 435px);
}
