.unauthorized-section {
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.err-title {
    margin-bottom: 10px;
    font-size: 90px;
    color: #fc0000;
    font-weight: 700;
    letter-spacing: 1.75px;
}
.err-description {
    margin-bottom: 18px;
    font-size: 20px;
    color: #000;
}
.go-back-btn {
    font-weight: 600;
}
.go-back-btn a {
    text-decoration: none;
}
