.post-tab.table-wrapper thead th:nth-of-type(2) {
    text-align: left;
}
.post-tab.table-wrapper td:nth-of-type(1) {
    text-align: left;
}
.post-tab.table-wrapper thead th:first-child {
    width: 150px;
    text-align: left;
}
.post-tab.table-wrapper td:first-child,
.post-tab.table-wrapper th:first-child {
    width: 150px;
    text-align: left;
}
