.header {
    width: 100%;
    height: 60px;
    padding: 10px 15px;
    background: #4ac1e0;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 9;
}
.header-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-pic-wrap ul {
    display: flex;
    align-items: center;
    margin: 0 -5px;
}
.user-pic-wrap li {
    padding: 0 5px;
}
.user-pic-wrap li a {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
}
.logout-icon button {
    min-width: 34px;
    width: 34px;
    height: 34px;
    padding: 0;
    background: #fff;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logout-icon button svg {
    font-size: 24px;
    color: #000;
    margin-left: 3px;
}
.logout-icon button:hover {
    background: #fff;
}
.user-pic-wrap li.user-icon svg {
    color: #4ac1e0;
    font-size: 25px;
    margin-bottom: 3px;
}
