.policys-wrapper {
  position: relative;
  min-height: 100vh;
  padding: 15px 15px 20% 15px;
  background-color: #000000;
}

.footer-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}
.footer-img img,
.footer-img figure {
  width: 100%;
  object-fit: contain;
}

.policys-wrapper.terms p strong {
  font-weight: bold;
}
.policys-wrapper p em {
  font-style: italic;
}
.policys-wrapper p {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
}
.policys-wrapper p br {
  display: none;
}
.policys-wrapper p:empty {
  display: none;
}
.policys-wrapper p span,
.policys-wrapper p a {
  color: #4ac1e0 !important;
  font-style: italic;
  text-decoration: none;
}
.policys-wrapper ul {
  padding-left: 25px !important;
  list-style: inside !important;
  color: #fff !important;
}

.policys-wrapper ul li {
  color: #fff !important;
}

.policys-wrapper ol {
  padding-left: 25px !important;
  list-style: decimal !important;
  color: #fff !important;
}
.policys-inner p span {
  color: #4ac1e0 !important;
  font-style: italic;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .policys-wrapper .loader-wrap {
    background: #000;
  }
}
