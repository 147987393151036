.password-info {
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.password-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title-left {
    display: flex;
    align-items: center;
}
.password-info .section-heading {
    font-weight: bold;
    color: #000000;
    font-size: 18px;
}
.MuiTextField-root {
    width: 100%;
}
.password-info .eye-icon {
    position: absolute;
    right: 15px;
    top: 28px;
}
.user-password-row {
    position: relative;
}
.form-group {
    position: relative;
}
.input-error fieldset {
    border-color: #ff0000 !important;
}
.input-error label {
    color: #ff0000;
}
.password-info .user-password-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.password-info .password-col {
    max-width: 33.33%;
    flex: 0 33.33%;
    padding: 0 8px;
}
