.loader-wrap {
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-relative{
    position: relative;
}
.loader-fixed{
    position: fixed;
}
.loader-inner {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}
.loader-wrap .MuiCircularProgress-root {
    width: 100px;
    height: 100px;
    color: #fff !important;
}

.loader-inner img {
    width: 80px;
    height: 80px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
