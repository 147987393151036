.table-wrapper.notification-list th:nth-of-type(2) {
    width: 350px;
}
.table-wrapper.notification-list thead th:last-child {
    width: 115px;
}
.table-wrapper.notification-list td:first-child,
.table-wrapper.notification-list th:last-child {
    width: 350px;
}
