.subadmin-wrap,
.user-wrap,
.permission-form {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.permission-form {
    margin-bottom: 0;
    padding: 15px 15px 3px 15px;
}
.subadmin-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
h1,
h3 {
    font-weight: bold;
    color: #000000;
}
h1 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}
.btn-group {
    padding: 0;
}
.btn-group button:last-child {
    margin-right: 0;
}
.btn-group button {
    margin-right: 15px;
}
.user-inner {
    padding-top: 15px;
}
.user-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.user-col {
    max-width: 33.33%;
    flex: 0 33.33%;
    padding: 0 8px;
}
.user-detail {
    width: 100%;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.7px;
}

.user-inner p {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
}
.form-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.breadcrumbs {
    margin: 15px 0 5px;
}
.breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.breadcrumbs ul li a {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.breadcrumbs ul li strong {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.breadcrumbs ul li span {
    font-size: 16px;
    font-weight: 400;
    margin-left: 15px;
    position: relative;
}
.breadcrumbs ul li span:before {
    position: absolute;
    content: "";
    left: -8px;
    height: 13px;
    border: 1px solid #303030;
}
.form-checkbox span {
    font-size: 15px;
    font-weight: 700;
}
.radio-btn span {
    font-size: 15px;
}
.radio-btn label {
    margin-right: 25px;
}
.add-permission-form {
    padding: 15px 15px 3px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.add-permission-form h3 {
    margin: 15px 0 5px;
}
.permission-form.form {
    padding-bottom: 10px;
}
.subadmin-profile-inner {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.subadmin-profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
}
.subadmin-profile-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.subadmin-profile-left {
    max-width: 140px;
    flex: 0 0 140px;
}
.subadmin-profile-right {
    max-width: calc(100% - 205px);
    flex: 0 0 calc(100% - 205px);
}
