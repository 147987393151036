.profile-info {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.profile-info .btn-wrap {
    padding: 0;
}
.profile-info .btn-wrap button {
    max-width: 65px;
    flex: 0 0 65px;
}
.profile-info .btn-wrap a {
    text-decoration: none;
}
.profile-info .profile-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.profile-info .profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
}
.profile-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.profile-left {
    max-width: 140px;
    flex: 0 0 140px;
}
.profile-right {
    max-width: calc(100% - 205px);
    flex: 0 0 calc(100% - 205px);
}
.profile-inner-row {
    display: flex;
    flex-wrap: wrap;
}
.profile-col {
    max-width: 50%;
    flex: 0 0 50%;
}
.profile-inner p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 1.2;
}
.profile-detail {
    width: 100%;
    margin-bottom: 6px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
}
.profile-col.top-spacing {
    margin-top: 20px;
}
