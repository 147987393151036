.table-wrapper.add-notification-list thead th:first-child {
    text-align: center;
    width: 80px;
}
.table-wrapper.add-notification-list th:first-child {
    text-align: center;
    width: 80px;
}

.table-wrapper.add-notification-list thead th:first-child .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: #fff;
}
