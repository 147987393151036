.report-wrap,
.user-wrap {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.report-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.report-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.report-subtitle a {
    color: #4ac1e0;
    font-size: 16px;
    text-decoration: none;
}
.report-subtitle a:hover {
    text-decoration: underline;
}
.btn-group {
    padding: 0;
}
.btn-group button {
    margin-right: 15px;
}
.btn-group button:last-child {
    margin-right: 0;
}
.user-inner {
    padding-top: 15px;
}
.user-inner-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -8px;
}
.user-col {
    max-width: 33.33%;
    flex: 0 33.33%;
    padding: 0 8px;
}
.user-profile-inner {
    padding-top: 15px;
    display: flex;
}
.user-detail {
    width: 100%;
    margin-bottom: 6px;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.7px;
}
.user-inner p {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
}
.report-wrap .report-user-image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
}
.report-user-image img,
.report-user-image figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
label.user-detail.bottom-spacing {
    margin-bottom: 8px;
}
.report-user-left {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.report-user-right {
    width: calc(100% - 160px);
}
.user-info-col {
    max-width: 49.99%;
    flex: 0 49.99%;
    padding: 0 8px;
}
.user-col.top-spacing {
    margin-top: 15px;
}
