.rankchange : {
  display: "flex";
  width: 100%;
}

.rankchange .muiinputbase-root.muioutlinedinput-root: {
  width: 200px;
}

.heading : {
  margin-bottom: 20px;
}
