.sport-wrap,
.sport-title-info {
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.permission-form {
    margin-bottom: 0;
    padding: 15px 15px 3px 15px;
}
.sport-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title-left {
    display: flex;
    align-items: center;
}
.title-left a svg {
    width: 20px;
    height: 17px;
    margin-right: 3px;
    vertical-align: middle;
    color: #000;
}
.title-left a:hover svg {
    color: #677381;
}
.title-info {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}
.sport-detail-info {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.info-sport-icon {
    margin-left: 100px;
    width: 94px;
    height: 94px;
    flex: 0 0 94px;
    background-color: rgb(128 128 128 / 30%);
    padding: 3px;
    border-radius: 3px;
    overflow: hidden;
}
.info-sport-icon figure,
.info-sport-icon figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sport-detail-info label {
    font-size: 16px;
    letter-spacing: 0.5px;
}
.info-sport-title strong {
    font-size: 16px;
    font-weight: 700;
    margin-left: 92px;
    letter-spacing: 0.5px;
}
.info-sport-description {
    padding-top: 15px;
    display: flex;
}
.sub-title {
    font-size: 18px;
    font-weight: 700;
}
.sport-detail-list .table-wrapper thead th:last-child {
    width: 115px;
}

.sport-detail-list .table-wrapper td:last-child,
.sport-detail-list .table-wrapper th:last-child {
    width: 115px;
}
.sport-detail-list .table-wrapper thead th:first-child {
    width: 400px;
}
.sport-detail-list .table-wrapper td:first-child,
.sport-detail-list .table-wrapper th:first-child {
    width: 400px;
}
