.accountWrapper {
    height: 100vh;
}
.accountInner {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.right-section {
    width: 560px;
    height: 100%;
    border-left: 1px solid #01bade;
}
.right-inner {
    height: 100%;
    padding: 0 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.input-row {
    width: 100%;
    margin-bottom: 25px;
    position: relative;
}
.form-wrapper {
    width: 100%;
    padding: 25px 0 0;
}
.right-section .input-error fieldset {
    border-color: #ff0000 !important;
}
.right-section .input-error label {
    color: #ff0000;
}
.normal-btn .MuiButtonBase-root.MuiButton-root {
    font-family: "helvetica_mediumregular", sans-serif;
}
