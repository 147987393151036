.policys-wrapper-aboutus {
  position: relative;
  min-height: 100vh;
  padding: 0;
  background-color: #000000;
  z-index: 1;
}
.aboutus-footer-img {
  position: absolute;
  z-index: -1;
  background: url("../../../assets/images/About-Us.png") no-repeat;
  min-height: 390px;
  background-size: contain;
  background-position: center center;
}
.policys-wrapper-aboutus .policys-inner {
  padding: 15px 15px 300px;
}
.aboutus-footer-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.aboutus-footer-img figure {
  width: 100%;
  height: 600px;
}
.aboutus-footer-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.policys-wrapper-aboutus p strong {
  font-weight: 500;
  font-style: italic;
}
.policys-wrapper-aboutus p em {
  font-style: italic;
}
.policys-wrapper-aboutus p {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
}
.policys-wrapper-aboutus p u,
.policys-wrapper-aboutus p a {
  color: #4ac1e0 !important;
  font-style: italic;
  text-decoration: none;
}

.policys-wrapper-aboutus p br {
  display: none;
}
.policys-wrapper-aboutus p:empty {
  display: none;
}
.policys-wrapper-aboutus ul {
  padding-left: 25px !important;
  list-style: inside !important;
  color: #fff !important;
}

.policys-wrapper-aboutus ul li {
  color: #fff !important;
}

.policys-wrapper-aboutus ol {
  padding-left: 25px !important;
  list-style: decimal !important;
  color: #fff !important;
}
.aboutus-footer-img {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.aboutus-footer-img figure {
  width: 100%;
}
.aboutus-footer-img figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutus-footer-img-mb {
  display: none;
}

@media screen and (max-width: 767px) {
  .policys-wrapper .loader-wrap {
    background: #000;
  }
  .aboutus-footer-img.d-none {
    display: none;
  }
  .aboutus-footer-img-mb {
    height: 375px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background-image: url("../../../assets/images/aboutusFooter.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .aboutus-footer-img-mb {
    display: block;
  }
}
