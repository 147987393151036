.pagination-wrap{
    padding:25px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.select-dropdown{
    width:100px;
}
.filterWrap {
    display: flex;
    align-items: center;
}
.countWrap{
    padding-left:10px;
}
.countWrap p{
    font-size:14px;
    margin-bottom:0;
}
.countWrap p b{
    font-weight:600;
}