.margin {
  margin-bottom: 20px;
}

.btn-group button {
  margin-right: 15px;
}

.btn-group button:last-child {
  margin-right: 0;
}

.chip-section {
  margin-bottom: 20px;
}

.chip-section div {
  margin-bottom: 10px;
  background-color: #4ac1e0;
  color: white;
}

.chip-section div span {
  font-weight: bold;
}

.chip-section div svg {
  color: black;
}
