.sidebar {
    width: 260px;
    height: 100%;
    position: fixed;
    left: 0;
    z-index: 99;
    background: #f6fbff;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    border-right: solid 1px #ddd;
}
.sidebarInner {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 80px;
}
.sidebar-menu a {
    padding: 10px 15px;
    height: 44px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.4;
    color: #505050;
    transition: 0.3s ease-in;
}
.sidebar-menu .sub-menu {
    background: #f4f4f4;
    list-style: none;

}
.sidebar-menu .sub-menu a {
    padding: 10px 15px 10px 45px;
}
.sidebar-menu a:hover {
    background-color: #4ac1e0;
    color: #fff;
}
.sidebar-menu i.icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-size: 100% 100% !important;
    transition: 0.3s ease-in;
}
.sidebar-menu a .dashboard {
    background: url("../../../assets/images/dashboard.svg") center center no-repeat;
}
.sidebar-menu a:hover .dashboard {
    background: url("../../../assets/images/dashboard-hover.svg") center center no-repeat;
}
.sidebar-menu a .user {
    background: url("../../../assets/images/user-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .user {
    background: url("../../../assets/images/user-management-hover.svg") center center no-repeat;
}
.sidebar-menu a .athlete {
    background: url("../../../assets/images/athlete-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .athlete {
    background: url("../../../assets/images/athlete-hover.svg") center center no-repeat;
}
.sidebar-menu a .fan {
    background: url("../../../assets/images/fan-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .fan {
    background: url("../../../assets/images/fan-hover.svg") center center no-repeat;
}
.sidebar-menu a .subadmins {
    background: url("../../../assets/images/subadmin-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .subadmins {
    background: url("../../../assets/images/subadmins-hover.svg") center center no-repeat;
}
.sidebar-menu a .report {
    background: url("../../../assets/images/report-content-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .report {
    background: url("../../../assets/images/report-hover.svg") center center no-repeat;
}
.sidebar-menu a .feed {
    background: url("../../../assets/images/feed-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .feed {
    background: url("../../../assets/images/feed-hover.svg") center center no-repeat;
}
.sidebar-menu a .notification {
    background: url("../../../assets/images/notification-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .notification {
    background: url("../../../assets/images/notification-hover.svg") center center no-repeat;
}
.sidebar-menu a .earning {
    background: url("../../../assets/images/earning-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .earning {
    background: url("../../../assets/images/earning-hover.svg") center center no-repeat;
}
.sidebar-menu a .content {
    background: url("../../../assets/images/content-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .content {
    background: url("../../../assets/images/content-hover.svg") center center no-repeat;
}
.sidebar-menu a .sport {
    background: url("../../../assets/images/sport-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .sport {
    background: url("../../../assets/images/sport-management-hover.svg") center center no-repeat;
}
.sidebar-menu a .support {
    background: url("../../../assets/images/support-management.svg") center center no-repeat;
}
.sidebar-menu a:hover .support {
    background: url("../../../assets/images/support-hover.svg") center center no-repeat;
}
.sidebar-menu .parent-menu a.arrow {
    position: relative;
}
.sidebar-menu .parent-menu a.arrow:before {
    width: 7px;
    height: 7px;
    margin-top: -4px;
    border-bottom: 2px solid #505050;
    border-right: 2px solid #505050;
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 43%;
    transform: rotate(45deg);
    transition: 0.3s ease-in;
}
.sidebar-menu .parent-menu a.arrow:hover:before {
    border-color: #fff;
}
.sidebar-menu ul.sub-menu li a:hover {
    background-color: #4ac1e0;
}
.down-arrow:hover a.arrow:before {
    transform: rotate(225deg);
    top: 50%;
}
.sidebar-menu a.active .dashboard {
    background: url("../../../assets/images/dashboard-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .user {
    background: url("../../../assets/images/user-management-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .athlete {
    background: url("../../../assets/images/athlete-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .fan {
    background: url("../../../assets/images/fan-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .subadmins {
    background: url("../../../assets/images/subadmins-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .report {
    background: url("../../../assets/images/report-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .feed {
    background: url("../../../assets/images/feed-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .notification {
    background: url("../../../assets/images/notification-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .earning {
    background: url("../../../assets/images/earning-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .content {
    background: url("../../../assets/images/content-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .sport {
    background: url("../../../assets/images/sport-management-hover.svg") center center no-repeat;
}
.sidebar-menu a.active .support {
    background: url("../../../assets/images/support-hover.svg") center center no-repeat;
}
a.active {
    background-color: #4ac1e0;
    color: #fff;
}
