.sport-icon {
    width: 40px;
    height: 40px;
    overflow: hidden;
}
.sport-icon figure,
.sport-icon figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sport-icon.bg {
    background-color: rgb(128 128 128 / 28%);
    padding: 3px;
    border-radius: 3px;
}
