.left-section {
    width: calc(100% - 560px);
    height: 100%;
    padding: 25px;
    background: url("../../../assets/images/admin-bg.png");
    background-size: cover;
}

.logo-wrap {
    width: 120px;
}  

/* Responsive for Tablet View */
@media (max-width: 1024px) {
    .left-section {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo-wrap {
        width: 200px;
    }
}

/* Responsive for Mobile View */
@media (max-width: 767px) {
    .left-section {
        width: 100%;
        height: auto;
        padding: 30px 20px;
        display: flex;
        align-items: center;
    }
    .logo-wrap {
        margin: 0 auto;
        width: 150px;
    }
}
