.edit-profile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.edit-profile-wrap {
    min-height: calc(100vh - 105px);
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.edit-profile-info {
    padding: 15px 0;
}
.upload-profile-image {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.upload-profile-image figure,
.upload-profile-image figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.upload-profile-image .upload-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
.upload-profile-image .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}
.close-icon .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    color: red;
    transition: all 0.3s ease-in;
    cursor: pointer;
}
.close-icon:hover svg {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-icon svg {
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #d8d8d8;
    color: #4ac1e0;
    transition: all 0.3s ease-in;
}
.upload-icon:hover svg {
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.upload-profile-file {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: #f2f2f2;
}
.upload-profile-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}
.upload-profile-file .upload-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.upload-profile-wrap {
    display: flex;
}
.addprofile-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.addprofile-col {
    max-width: 50%;
    flex: 0 50%;
    padding: 0 8px;
}
.addprofile-col.top-spacing {
    margin-top: 15px;
}
.country-name-section {
    display: flex;
}
.country-code {
    width: 155px;
    margin-right: 6px;
}
.country-text {
    width: calc(100% - 161px);
}
