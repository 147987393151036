.ql-editor strong {
    font-weight: bold;
}
.ql-editor em {
    font-style: italic;
}
.ql-tooltip.ql-editing.ql-flip {
    left: 15px !important;
    top: 9px !important;
}

.ql-editor p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #000000;
}
.ql-editor {
    height: 200px;
}
