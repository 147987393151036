.static-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.static-content .tabs-section {
    margin-top: 15px;
    width: 100%
}

.static-content .MuiTabs-flexContainer{
    display: flex;
    flex-wrap: wrap !important;
}

.static-content .MuiTabs-flexContainer button{
    margin-bottom: 15px !important;
}