.addathlete-info {
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.addathlete-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.addathlete-col {
    max-width: 50%;
    flex: 0 50%;
    padding: 0 8px;
}
.addathlete-col.top-spacing {
    margin-top: 15px;
}
.country-name-section {
    display: flex;
}
.country-code {
    width: 155px;
    margin-right: 6px;
}
.country-text {
    width: calc(100% - 161px);
}
