.sport-list .table-wrapper thead th:first-child {
    width: 115px;
}
.sport-list .table-wrapper td:first-child,
.sport-list .table-wrapper th:first-child {
    width: 115px;
}
.btn-group a {
    text-decoration: none;
}
.sport-list.table-wrapper thead th {
    padding: 5px 15px;
}
.sport-list .table-wrapper thead th:last-child {
    width: 115px;
}
.sport-list .table-wrapper td:last-child,
.sport-list .table-wrapper th:last-child {
    width: 115px;
}
