@font-face {
  font-family: "helvetica_neueregular";
  src: url("assets/fonts/helveticaneueblackitalic_1-webfont.woff2")
      format("woff2"),
    url("assets/fonts/helveticaneueblackitalic_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helvetica_neue66MdIt";
  src: url("assets/fonts/helveticaneuemediumitalic-webfont.woff2")
      format("woff2"),
    url("assets/fonts/helveticaneuemediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helvetica_neue66MdIt";
  src: url("assets/fonts/helveticaneue-mediumitalic-webfont.woff2")
      format("woff2"),
    url("assets/fonts/helveticaneue-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helvetica-blackitalicregular";
  src: url("assets/fonts/helvetica-blackitalic-webfont.woff2") format("woff2"),
    url("assets/fonts/helvetica-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "helvetica_mediumregular";
  src: url("assets/fonts/helvmn-webfont.woff2") format("woff2"),
    url("assets/fonts/helvmn-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-family: "helvetica_mediumregular", sans-serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
  border: none;
}
* {
  box-sizing: border-box;
}
a,
button {
  cursor: pointer;
}
p {
  word-break: keep-all;
}
td.status-active,
td.status-unblock,
td.Resolved {
  color: #2e7d32;
}
td.status-block,
td.Pending {
  color: #c62828;
}
/* rich-text-editor */
h2.text-editor-heading {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  color: #000;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
}
/* rich-text-editor */

.MuiMenu-list {
  max-height: 230px;
}
.pageWrapper {
  padding-top: 60px;
  position: relative;
}
.main-section-inner {
  min-height: calc(100vh - 90px);
  padding: 15px;
  background: #fff;
}
.main-section {
  width: calc(100% - 260px);
  min-height: calc(100vh - 60px);
  padding: 15px;
  float: right;
  background: #f2f2f2;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.serach-wrap {
  width: calc(100% - 40%);
}
.serach-wrap h3 {
  font-size: 16px;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
}
.search-filter-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filterPopup {
  width: 360px;
  padding: 15px;
}
.btn-wrap {
  padding: 18px 0 3px 0;
}
.btn-wrap button {
  width: 157px;
  margin-right: 15px;
}
.btn-wrap button:last-child {
  margin-right: 0;
}
.table-wrapper thead th {
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  background: #4ac1e0;
  white-space: nowrap;
  text-align: left;
}
.table-wrapper td,
.table-wrapper th {
  padding: 5px 15px;
  white-space: nowrap;
  text-align: left;
}
.table-wrapper th a,
.table-wrapper td a {
  color: #4ac1e0;
  text-decoration: none;
}
.table-wrapper th a:hover,
.table-wrapper td a:hover {
  text-decoration: underline;
}
.table-wrapper thead th:first-child {
  width: 150px;
  text-align: left;
}
.table-wrapper td:first-child,
.table-wrapper th:first-child {
  width: 150px;
  text-align: left;
}
.tagged-user.table-wrapper thead th:first-child {
  width: 300px;
  text-align: left;
}
.tagged-user.table-wrapper td:first-child,
.tagged-user.table-wrapper th:first-child {
  width: 300px;
  text-align: left;
}
.table-wrapper td:last-child,
.table-wrapper th:last-child {
  width: 150px;
}
.rank-tab.table-wrapper td:nth-of-type(1),
.rank-tab.table-wrapper.table-wrapper thead th:nth-of-type(2) {
  padding: 10px 15px;
  text-align: left;
}
.rank-tab.table-wrapper thead th:last-child {
  width: 60px;
}
.rank-tab.table-wrapper thead th:first-child {
  width: 500px;
  text-align: left;
}
.rank-tab.table-wrapper td:first-child,
.rank-tab.table-wrapperth:first-child {
  width: 500px;
  text-align: left;
}
.rank-tab.table-wrapper td:last-child,
.rank-tab.table-wrapper th:last-child {
  width: 60px;
}
.reported-by-tab.table-wrapper thead th:first-child {
  width: 80px;
}
.reported-by-tab.table-wrapper td:first-child,
.reported-by-tab.table-wrapper th:first-child {
  width: 80px;
}
.reported-by-tab.table-wrapper thead th:nth-of-type(2) {
  padding: 10px 15px;
  width: 100px;
}
.reported-by-tab.table-wrapper td:nth-of-type(2),
.reported-by-tab.table-wrapper th:nth-of-type(2) {
  padding: 10px 15px;
  width: 100px;
}
.table-wrapper.subscription thead th {
  width: 25%;
}

.video-tagged-user.table-wrapper td:nth-of-type(3),
.video-tagged-user.table-wrapper th:nth-of-type(3),
.tagged-user.table-wrapper td:nth-of-type(3),
.tagged-user.table-wrapper th:nth-of-type(3) {
  padding: 10px 15px;
  width: 420px;
}
.table-wrapper td button span:hover {
  color: #4ac1e0;
}
.table-wrapper td button:hover,
.table-wrapper td button:active {
  background-color: transparent;
}
.box-height {
  height: 140px;
}
.breadcrumb-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
.textLabel,
.form-label {
  font-size: 15px;
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}
.error-msg {
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  font-size: 13px;
  color: #ff0000;
  text-align: left;
}
.filter-text {
  padding-top: 15px;
}
.filter-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.filter-col {
  max-width: 0 49.99%;
  flex: 0 49.99%;
  padding: 0 8px;
}
.sort-by {
  width: 20px;
  display: inline-block;
  margin-left: 10px;
  position: relative;
}
.sort-by-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sort-outer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sort-by.sort-disabled .sort-by-inner svg {
  opacity: 0.3;
}
.sort-by-inner svg {
  cursor: pointer;
}
.action-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.title-left {
  display: flex;
  align-items: center;
}
.title-left svg,
.breadcrumb-left svg {
  width: 20px;
  height: 17px;
  margin-right: 3px;
  vertical-align: middle;
  color: #000;
  cursor: pointer;
}
.title-left svg:hover,
.breadcrumb-left svg:hover {
  color: #677381;
}
.section-title,
.section-sub-title .section-heading {
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #000000;
}
.section-title,
.section-sub-title {
  font-size: 20px;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 700;
}
.section-heading {
  font-size: 18px;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 700;
}
.tabs-section .MuiTabPanel-root {
  padding: 15px 0;
}
.tabs-section .MuiButtonBase-root.MuiTab-root {
  margin-right: 20px;
  background-color: #d0d0d0;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 11px 15px 10px;
  min-height: 44px;
}
.tabs-section .MuiTabs-indicator {
  display: none;
}
.tabs-section .MuiTabs-root .MuiButtonBase-root.Mui-selected {
  background-color: #4ac1e0;
}
.title {
  font-size: 20px;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
}
.MuiButtonBase-root.MuiButton-root {
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 0;
}
a {
  cursor: pointer;
}
.sort-by .sort-by-inner svg:first-child {
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.sort-by .sort-by-inner svg:last-child {
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
}
.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background-color: #44c2e3;
}
.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
  border: 1px solid #44c2e3;
  color: #44c2e3;
}
.pagination .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #44c2e3;
}
.pagination .MuiPaginationItem-root.Mui-selected {
  background-color: #4ac1e0;
}
.MuiSvgIcon-root.MuiSvgIcon-colorPrimary {
  color: #4ac1e0;
}
.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 0;
}
.action-wrap
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary,
.edit-text
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary {
  color: #4ac1e0;
}
.MuiDialog-container .MuiTypography-h6 {
  padding: 13px 15px;
  font-weight: 700;
  background-color: #4ac1e0;
  color: #fff;
  letter-spacing: 0.5px;
}
.MuiDialog-container .MuiPaper-root .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
}
.MuiDialog-container .MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  padding: 25px 25px 15px;
}
.MuiDialog-container .MuiDialogActions-root > :not(:first-of-type) {
  margin-left: 15px;
}
.MuiDialog-container .MuiDialogContent-root {
  border: none;
}
.MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: #4ac1e0 !important;
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #4ac1e0 !important;
}
thead .MuiTableCell-root .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #ffffff !important;
}
.MuiDialog-container .MuiDialogContent-root {
  padding: 25px !important;
}
.MuiDialog-container .MuiDialogActions-root {
  padding: 15px 35px 25px !important;
  justify-content: center !important;
}
.MuiDialog-container .MuiDialogActions-root button {
  width: 50%;
  height: 42px;
  flex: 0 0 50%;
}
.MuiDialog-container a {
  color: #4ac1e0;
  text-decoration: none;
}
.MuiDialog-container a:hover {
  text-decoration: underline;
}
.user-col-half {
  max-width: 50%;
  flex: 0 1 50%;
  padding: 0 8px;
}
.user-col-full {
  max-width: 100%;
  flex: 0 1 100%;
  padding: 0 8px;
}
p.rank-rating {
  text-align: right;
}
.popup-inner {
  padding: 0 0 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.user-title {
  display: flex;
  align-items: center;
}
.user-pic {
  width: 40px;
  margin-right: 8px;
  flex: 0 0 40px;
  border-radius: 50%;
  overflow: hidden;
}
.user-pic img,
.user-pic figure {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.report-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-middle {
  text-align: center;
}
.profile-btn {
  text-align: right;
}
.athlete-reported-popup button,
.athlete-reported-popup .profile-btn button {
  font-size: 12px;
}

.delete-user {
  font-weight: 900 !important;
  font-size: 16px !important;
}

p.rank-rating,
p.user-name {
  margin-bottom: 0;
  font-size: 14px;
}
.dialog-title {
  font-size: 18px !important;
  text-align: center;
}
.dialog-description {
  font-size: 16px !important;
}
.breadcrumb-right a {
  text-decoration: none;
}
.table-wrapper .MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
  font-size: 13px;
}
.static-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-group button:last-child {
  margin-right: 0;
}
.btn-group button {
  margin-right: 15px;
}
.text-editor {
  margin-top: 15px;
}

/* card-section */
.card-section {
  padding: 15px;
  margin: 0 0 15px;
  border-radius: 5px;
  background: #fafafa;
}
.card-row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}
.card-col {
  padding: 0 15px;
  width: 170px;
}
.card-info {
  height: 140px;
  padding: 0 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #28d3db;
  position: relative;
}
.card-info:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: #fafafa transparent transparent transparent;
}
.info-title {
  margin-bottom: 10px;
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
  text-align: center;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
}
.info-value p {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
}
.input-error fieldset {
  border-color: #ff0000 !important;
}
.input-error label {
  color: #ff0000;
}
.add-form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.add-form-col {
  max-width: 33.33%;
  padding: 0 10px;
  flex: 0 33.33%;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  color: #474554 !important;
}

/* Static-Content */
.policy-wrapper .cms-content {
  margin-bottom: 15px;
  border: solid 1px #d8d8d8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.policy-wrapper .cms-content:last-child {
  margin-bottom: 0;
}
.policy-wrapper .cms-header {
  padding: 10px 15px;
  border-bottom: solid 1px #d8d8d8;
  background-color: rgba(128, 128, 128, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.policy-wrapper .cms-header h2 {
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  color: #000;
  font-family: "helvetica_neue66MdIt", sans-serif;
  letter-spacing: 0.5px;
}
.policy-wrapper .cms-header a {
  color: #4ac1e0;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.policy-wrapper .cms-header a:hover {
  text-decoration: underline;
}
.policy-wrapper .content-description {
  padding: 15px;
}
.policy-wrapper .content-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.policy-wrapper .content-box {
  margin-bottom: 15px;
  padding: 15px 8px 15px 15px;
  border: solid 1px #d8d8d8;
  border-radius: 5px;
}
.policy-wrapper .content-box:last-child {
  margin-bottom: 0;
}
.content-main p > br {
  display: none;
  margin: 0;
}
.content-main p br,
.content-main p:empty {
  margin: 0;
  display: none;
}
.policy-wrapper .content-description p {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
}
.policy-wrapper .content-description p:last-child {
  margin-bottom: 0;
}
/* Static-Content */
/* Text-Editor */
.ql-editor strong {
  font-weight: bold;
}
.ql-editor em {
  font-style: italic;
}
.ql-tooltip.ql-editing.ql-flip {
  left: 15px !important;
  top: 9px !important;
}

.ql-editor p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
}
.ql-editor {
  height: 200px;
}

.content-main p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
}
.content-main strong {
  font-weight: bold;
}
.content-main em {
  font-style: italic;
}
.content-main p {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
}

.content-main ul {
  padding-left: 25px !important;
  list-style: inside !important;
}

.content-main ol {
  padding-left: 25px !important;
  list-style: decimal !important;
}
/* Text-Editor */
