.report-info-section {
    padding: 25px 0 5px;
}
.flex-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.flex-col {
    max-width: 33.33%;
    flex: 0 33.33%;
    padding: 0 15px 10px;
}
.report-info-section label {
    font-size: 15px;
    font-weight: 700;
}
.report-info-section a {
    color: #4ac1e0;
    font-weight: 500;
    text-decoration: none;
}
.report-info-section a:hover {
    text-decoration: underline;
}
.report-info-section p {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.361px;
    color: #000000;
    opacity: 0.7;
}
.moderator-loader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.moderator-loader .moderator-label {
    font-weight: 700;
    margin: 0 auto;
}
.loader-btn {
    display: flex;
    justify-content: center;
    width: 100%;
}
.loader-btn .MuiSvgIcon-root {
    color: #000;
}
