.table-wrapper thead th:first-child {
    width: 80px;
}
.table-wrapper thead th:last-child {
    width: 150px;
}
.video-list .search-sub-wrap {
    display: flex;
    align-items: center;
}
.search-sub-wrap .search-wrap {
    width: 758px !important;
}
.video-list .video-info-title {
    margin-left: 15px;
    color: #000;
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 700;
}
